<template>
  <div>
    <h4>{{ i18n['profile-basic-info'].tcCandidateDetails }}</h4>
    <div class="specs">
      <p>
        <strong>{{ i18n['profile-basic-info'].tcRecommendedBy }}:</strong>
        {{ profile.referral_formal_name }}
      </p>
      <p
        v-if="
          iCanSee(
            secured_link_view_controls.view_non_member_church_membership_link
          )
        "
      >
        <strong>{{ i18n['profile-basic-info'].tcChurchMembership }}:</strong>
        {{ profile.church_name }}
      </p>
      <p v-if="profile.prospect_met_key != auxiliaryType">
        <strong>{{ i18n['profile-basic-info'].tcOccupation }}:</strong>
        <br />
        {{ profile.occupation }}
      </p>
      <p>
        <strong>{{ i18n['profile-basic-info'].tcOtherInformation }}:</strong>
        <br />
        {{ profile.comment }}
      </p>
      <router-link :to="{ name: 'prospect-details' }" class="btn btn-primary"
        >{{ i18n['profile-basic-info'].tcViewMore }}</router-link
      >
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex'

export default {
  name: 'profile-basic-info',
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'profile-basic-info': {
            tcCandidateDetails: 'Candidate Details',
            tcChurchMembership: 'Church Membership',
            tcOccupation: 'Occupation',
            tcOtherInformation: 'Other Information',
            tcRecommendedBy: 'Recommended By',
            tcViewMore: 'View More',
          },
        }
      },
    },
    profile: {
      recommendBy: {
        type: String,
        default: '',
      },
      occupation: {
        type: String,
        default: '',
      },
      church_name: {
        type: String,
        default: '',
      },
      comment: {
        type: String,
        default: '',
      },
    },
  },
  data() {
    return {
      auxiliaryType: '678cfa9f-3ced-4880-ab74-6bad634aaf74',
      secured_link_view_controls: {
        view_non_member_church_membership_link:
          '72fe914d-cd7c-4948-b442-a9e6525673c7',
      },
    }
  },
  computed: {
    ...mapGetters({
      iCanSee: 'user/iCanSee',
    }),
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';
</style>
